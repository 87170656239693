import React from 'react'
import './Donation.css'


const Donation=()=> {
  return (
    <>
    <div className='Main'>
        <div className='Bank'></div>
        <div className='QR-code'></div>

    </div>
    <a><button className='button'>Pay</button></a> 
    </>
  )
}
export default Donation